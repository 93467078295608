import React, { useEffect, useRef } from "react"
import { Frame, addPropertyControls, ControlType, motion } from "framer"

function easeIn(t) {
    return t * t
}
function easeOut(t) {
    const p = t - 1
    return 1 - p * p
}

function easeInOut(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
}

function linearEasing(t) {
    return t
}

// AnimatedGraphic Component
const AnimatedGraphic = ({
    pathData,
    duration,
    imageSrc,
    size,
    loop,
    showPath,
    pathColor,
    pathThickness,
    easingType,
    mirror,
}) => {
    const graphicRef = useRef(null)
    const pathId = useRef(
        `path-${Math.random().toString(36).substr(2, 9)}`
    ).current

    useEffect(() => {
        const svgNamespace = "http://www.w3.org/2000/svg"
        const tempSvg = document.createElementNS(svgNamespace, "svg")
        const newPath = document.createElementNS(svgNamespace, "path")
        newPath.setAttribute("d", pathData)
        tempSvg.appendChild(newPath)
        document.body.appendChild(tempSvg)
        const pathLength = newPath.getTotalLength()
        document.body.removeChild(tempSvg)

        let startTime = null
        let animationFrameID = null

        const animate = (timestamp) => {
            if (!startTime) startTime = timestamp
            const elapsedTime = timestamp - startTime
            let progress = elapsedTime / (duration * 1000)
            if (loop && progress >= 1) {
                if (mirror) {
                    progress = 1 - (progress % 1)
                } else {
                    startTime = timestamp - (elapsedTime % (duration * 1000))
                    progress %= 1
                }
            }

            let easedProgress = easingFunction(progress)

            const point = newPath.getPointAtLength(easedProgress * pathLength)
            const offsetX = point.x - size / 2
            const offsetY = point.y - size / 2

            // Get the next point to determine the path's tangent angle
            const nextPoint = newPath.getPointAtLength(
                Math.min(easedProgress * pathLength + 1, pathLength)
            )
            const dx = nextPoint.x - point.x
            const dy = nextPoint.y - point.y
            const angle = Math.atan2(dy, dx) * (180 / Math.PI)

            if (graphicRef.current) {
                graphicRef.current.style.transform = `translate(${offsetX}px, ${offsetY}px) rotate(${angle}deg)`
            }

            if (progress < 1 || loop) {
                requestAnimationFrame(animate)
            }
        }

        requestAnimationFrame(animate)
    }, [pathData, duration, loop, size, easingType, mirror])

    const style = {
        position: "absolute",
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: "transparent",
        transform: "translate(-50%, -50%)",
        willChange: "transform",
        zIndex: 2,
    }

    const pathStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        pointerEvents: "none",
    }

    let easingFunction
    switch (easingType) {
        case "easeIn":
            easingFunction = easeIn
            break
        case "easeOut":
            easingFunction = easeOut
            break
        case "easeInOut":
            easingFunction = easeInOut
            break
        case "linear":
        default:
            easingFunction = linearEasing
            break
    }

    return (
        <>
            {showPath && (
                <svg style={pathStyle}>
                    <path
                        d={pathData}
                        fill="none"
                        stroke={pathColor}
                        strokeWidth={pathThickness}
                    />
                </svg>
            )}
            <motion.div
                ref={graphicRef}
                style={style}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                whileHover={{ scale: 1.2 }}
            >
                <img
                    src={imageSrc}
                    alt=""
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                    }}
                />
            </motion.div>
        </>
    )
}

// My AnimatedPath Component with Property Controls
export function MyAnimatedPath({
    pathData,
    duration,
    imageSrc,
    size,
    loop,
    showPath,
    pathColor,
    pathThickness,
    easingType,
    mirror,
}) {
    return (
        <Frame size="100%" background={null} style={{ overflow: "visible" }}>
            <AnimatedGraphic
                pathData={pathData}
                duration={duration}
                imageSrc={imageSrc}
                size={size}
                loop={loop}
                showPath={showPath}
                pathColor={pathColor}
                pathThickness={pathThickness}
                easingType={easingType}
                mirror={mirror}
            />
        </Frame>
    )
}

MyAnimatedPath.defaultProps = {
    pathData: "M10 80 Q 95 10 180 80",
    duration: 2,
    imageSrc: "",
    size: 20,
    showPath: false,
    pathColor: "#000",
    pathThickness: 2,
    easingType: "linear",
    mirror: false,
}

addPropertyControls(MyAnimatedPath, {
    pathData: {
        title: "Path",
        type: ControlType.String,
        defaultValue: "M10 80 Q 95 10 180 80",
    },
    duration: {
        title: "Duration",
        type: ControlType.Number,
        defaultValue: 2,
        min: 0.1,
        max: 30,
        step: 0.1,
    },
    imageSrc: {
        title: "Image Source",
        type: ControlType.Image,
        defaultValue: "",
    },
    size: {
        title: "Size",
        type: ControlType.Number,
        defaultValue: 20,
        min: 1,
        max: 100,
        step: 1,
    },
    loop: {
        title: "Loop",
        type: ControlType.Boolean,
        defaultValue: false,
        enabledTitle: "Enabled",
        disabledTitle: "Disabled",
    },
    showPath: {
        title: "Show Path",
        type: ControlType.Boolean,
        defaultValue: false,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    pathColor: {
        title: "Path Color",
        type: ControlType.Color,
        defaultValue: "#000",
    },
    pathThickness: {
        title: "Path Thickness",
        type: ControlType.Number,
        defaultValue: 2,
        min: 0,
        max: 20,
        step: 0.1,
    },
    easingType: {
        type: ControlType.Enum,
        title: "Easing Type",
        options: ["linear", "easeIn", "easeOut", "easeInOut"],
        optionTitles: ["Linear", "Ease In", "Ease Out", "Ease In Out"],
        defaultValue: "linear",
    },
    mirror: {
        title: "Mirror Looping",
        type: ControlType.Boolean,
        defaultValue: false,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
})
